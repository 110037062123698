import React from 'react';
import { NavigationContainer, Container } from './styles';
import { secondaryNavigationItems } from '../../fixtures/puzzleRoutes';
import { ThemeProvider } from '@emotion/react';
import { SecondaryNavigation } from '@times-web/newskit-components';
import { TimesWebLightTheme, useIsIdentified } from '@times-web/utils';

interface NavigationProps {
    slug: string;
}

export const Navigation: React.FC<NavigationProps> = ({ slug }) => {
    const isIdentified = useIsIdentified();
    const onSecondaryNavOpen = (isOpen: boolean) => {
        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = isOpen ? 'hidden' : 'inherit';
        }
    };

    return (
        <ThemeProvider theme={TimesWebLightTheme as any}>
            <Container
                className={`secondary-nav-container ${
                    isIdentified ? '' : 'logged-out'
                }`}
                stickyTop={isIdentified ? 98 : 162}
                stickyTopDesktop={60}
                stickyTopTablet={60}
            >
                <NavigationContainer>
                    <SecondaryNavigation
                        pageSlug={slug}
                        data={secondaryNavigationItems}
                        title="Puzzles"
                        stickyTop={98}
                        stickyTopDesktop={60}
                        stickyTopTablet={60}
                        onClick={(isSecondaryNavOpen) => {
                            onSecondaryNavOpen(isSecondaryNavOpen);
                        }}
                        clickHandler={() => undefined}
                        heightMobile="100vh"
                    />
                </NavigationContainer>
            </Container>
        </ThemeProvider>
    );
};
