import { Stack } from '@times-web/newskit-components';
import styled from '@emotion/styled';
import { getMediaQuery } from '../../utils/get-media-query';

export const StyledStack = styled(Stack)`
    padding-inline: ${({ theme }) => theme.spacePresets.space045};
    ${getMediaQuery('xs')} {
        padding-block: ${({ theme }) => theme.spacePresets.space070};
    }
    ${getMediaQuery('md')} {
        padding-block: ${({ theme }) => theme.spacePresets.space100};
    }
    ${getMediaQuery('lg')} {
        padding-block: ${({ theme }) => theme.spacePresets.space110};
    }
`;
